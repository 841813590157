<script>
  export default {
    name: 'DeleteScheduleRow',
    emits: ['close', 'delete'],
    props: {
        scheduleType: String,
        index: Number
    },
    methods: {
      close() {
        this.$emit('close');
      },
      deleteItem() {
        this.$emit('delete', this.index);
        this.close()
      },

    },
    mounted() {

    }
  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close" >
      <div class="modal" 
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop=""
        tabindex="-1"
        id="modal-container"
      >
        <div class="headImgContainer">
        </div>
        <header
          class="modal-header"
          id="modalTitle"
        >
          <div name="header" tabindex="0">
            Delete this {{ scheduleType }} row?
          </div>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
        </section>

        <footer class="modal-footer">
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close message"
            >
              Cancel
          </button>
          <button
          type="button"
          class="btn-yellow"
          @click="deleteItem"
          aria-label="Delete message"
          >
            Delete
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(0, 0, 0, 0.3); */
    background-color: rgba(253, 247, 242, 0.95);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    /* box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4); */
    box-shadow: rgb(160 170 180 / 40%) 0px 8px 24px;
    border-radius: 3%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 95%;
    max-width: 600px;
    padding: 100px 30px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
    column-gap: 20px;
  }

  .modal-header {
    justify-content: center;
    position: relative;
    font-weight: 700;
    font-size: 25px;
    /* border-bottom: 1px solid #eeeeee; */
    color: #333333;
    text-align: center;
    /* justify-content: space-between; */
  }

  .modal-footer {
    /* border-top: 1px solid #eeeeee; */
    justify-content: center;
    /* flex-direction: column; */
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    text-align: center;
    
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 150%;
/* or 30px */

text-align: center;
letter-spacing: -0.011em;
  }

  .btn-close {
    color: #333333;
    background: $white;
    border: 1px solid $grayLight;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.029em;
    border-radius: 28px;
    height: 40px;
    width: 150px;
  }
    button:hover,
    button:active {
    background-color: $grayLight !important;
}

.btn-yellow {
    color: #333333;
    background: #fec443;
    border: 1px solid white;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.029em;
    border-radius: 28px;
    height: 40px;
    width: 150px;
  }
    button:hover,
    button:active {
    background-color: #fed67b !important;
    border-color: white;
}
  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }

  .headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}
</style>
